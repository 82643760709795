const humanDate = (dateStr, type) => {
  const date = moment(dateStr)

  switch (type) {
  case 'day':
    return date.format('D MMMM YYYY')
  case 'week':
    return `${date.startOf('week').format('D MMM')} - ${date.endOf('week').format('D MMM YYYY')}`
  default:
    return date.format('MMMM YYYY')
  }
}

const formatNewDateTime = date => {
  if (moment(date).format('HHmm') === '0000') {
    const formatDate = moment(date).format('YYYY-MM-DD')
    const currentTime = moment().add(1, 'hour').startOf('hour').format('HH:mm')

    return moment(`${formatDate} ${currentTime}`).format('YYYY-MM-DD HH:mm')
  }

  return moment(date).format('YYYY-MM-DD HH:mm')
}

const formatEndDate = date => {
  moment(date).add(1, 'hour').format('YYYY-MM-DD HH:mm')
}

const strDate = date => moment(date).format('YYYY-MM-DD HH:mm')

const setDateType = type => {
  switch (type) {
  case 'day':
    return 'd'
  case 'week':
    return 'w'
  default:
    return 'M'
  }
}

export { humanDate, formatNewDateTime, formatEndDate, strDate, setDateType }
